import React, { useState } from "react";

import "../styles/layout.css";
import Header from "./page/header";
import useSound from "use-sound";
import boopSfx from "../images/InTheEnd.mp3";

export default function Layout({ children }) {
  const [play, { pause }] = useSound(boopSfx);
  const [isPlaying, setIsPlaying] = useState(false);
  return (
    <>
      <body
        onMouseEnter={() => {
          play();
        }}
        onMouseLeave={() => {
          pause();
        }}
        className="body"
      >
        {children}
      </body>
    </>
  );
}
