import * as React from "react";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import test from "../images/ld-gif.gif";
import DogVideo from "../images/twerk.mp4";

import Seo from "../components/seo";

export default function Home() {
  return (
    <Layout>
      <Seo title='what if u gettin jumped by LD and he say "TAKE THAT NIGGA NUTS"' />
      <div className="m-auto mt-32 text-white">
        <p className="text-center">
          hi my name <strong>LD</strong>
        </p>
        <p className="text-center">
          my <strong>balls</strong> got cut off
        </p>
        <p className="text-center">
          they got <strong>sent</strong> to the <strong>shadow</strong> realm
          bro
        </p>
        <p className="text-center">
          <strong>"exiled and eviscerated from reality"</strong>
        </p>
        <p className="text-center">
          why did <strong>Justin</strong> steal my <strong>balls</strong>
        </p>
        <p className="text-center text-red-600">
          <strong>why</strong>
        </p>
        <p className="text-center">
          my final <strong>message</strong>
        </p>
        <p className="text-center">
          spread <strong>it</strong>
        </p>
        <p className="text-center">
          <strong>goodbye</strong>
        </p>
        <p className="text-center pb-10">
          <strong>meat</strong> meat
        </p>
        <div className=" w-full flex justify-around">
          <img src={test} />
          <video width="320" height="240" autoPlay muted loop>
            <source src={DogVideo} type="video/mp4" />
          </video>
        </div>
      </div>
    </Layout>
  );
}
